<template>
    <modal ref="actualizarStock" titulo="Actualizar stock" icon="Filledoutline" :btns="botones" no-aceptar @accion="accionBtn">
        <ValidationObserver ref="form2">
            <div class="row mx-0 mb-3 justify-center">
                <div class="col-8 f-15 text-center">
                    <label class="mb-2">Ingrese el valor de entrada o salida</label>
                    <ValidationProvider v-slot="{errors}" :rules="{required:true, decimal: 2}" name="cantidad">
                        <el-input v-model="model.valor" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Productos from "~/services/productos/productos"
export default {
    data(){
        return {
            botones: [
                {texto: 'Salida', color: 'bg-general', accion:'salida'},
                {texto: 'Entrada', color: 'bg-grr-red', accion: 'entrada'}
            ],
            model:{
                valor:null
            },
            producto:{}

        }
    },
    methods: {
        toggle(producto){
            this.producto = producto
            this.model.valor = null
            this.$refs.actualizarStock.toggle()
        },
        async accionBtn(accion){
            console.log(accion);

            const valid = await  this.$refs.form2.validate()

            if(valid){

                if(accion === 'entrada'){
                    this.model.tipo = 1
                }else if (accion === 'salida'){
                    this.model.tipo = 2
                }

                this.model.id_producto = this.producto.id
                const {data} = await Productos.actualizar_stock(this.model)

                this.notificacion('Mensaje','Stock del producto actualizado','success')

                this.$store.commit('productos/productos/update_productos', data.producto)
                this.$emit('update')
                this.$refs.actualizarStock.toggle()

            }


        }
    }
}
</script>

<style lang="css" scoped>
</style>
